import React from 'react'

// // Video for flexibleMediaContent
import { DemoIcon, PricingPlansIcon } from '../../lib/components/basics/icons'

// PREFOOTER (Used in PreFooter component)
export const preFooterTitle = 'See how Lenses works for you in production'

// CARDS DATA FOR PREFOOTER (Used in PreFooter component)
export const cardsDataPrefooter = [
    {
        title: 'Simple scalable plans',
        type: 'primary' as const,
        description: [],
        buttonText: 'See pricing',
        href: '/product/pricing/',
        icon: <PricingPlansIcon key='pricingIcon' />,
    },
    {
        title: (
            <>
                20 minute
                <br />
                demo
            </>
        ),
        type: 'secondary' as const,
        description: [
            'Guided tour of Lenses',
            'Trial key to install on your clusters',
            'Great for production and large-scale Kafka deployments',
        ],
        buttonText: 'Get a demo',
        href: '/request-demo',
        icon: <DemoIcon key='demo Icon' />,
    },
]
