// extracted by mini-css-extract-plugin
export var article = "styles-module--article--gQEkD";
export var blink = "styles-module--blink--pkdXA";
export var diagram = "styles-module--diagram--1dclA";
export var headline = "styles-module--headline--P7eXf";
export var highlight = "styles-module--highlight--GvBMZ";
export var instructions = "styles-module--instructions--9HLpW";
export var moreInfo = "styles-module--moreInfo--0-gil";
export var section = "styles-module--section--qLySq";
export var section1 = "styles-module--section1--is3i-";
export var section2 = "styles-module--section2--puOor";
export var section3 = "styles-module--section3--xZHcR";
export var subHeadline = "styles-module--subHeadline--nyH0m";
export var title = "styles-module--title--+EBVN";
export var video = "styles-module--video--khGQ-";