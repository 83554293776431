import React from 'react'
import { preFooterTitle, cardsDataPrefooter } from './constants'
import PreFooter from '../../lib/components/modules/prefooter'
import { CodeBlock } from './components/code-block'
import { Diagram } from './components/diagram'

import * as myStyles from './styles.module.scss'

const CommunityEditionPage = () => {
    const styles: any = myStyles

    return (
        <div className='h-full bg-grey-98'>
            <div className='relative mt-10'>
                <section className={`${styles.section} ${styles.section1}`}>
                    <article className={styles.article}>
                        <h1 className={styles.headline}>Lenses Community Edition</h1>
                        <p className={styles.subHeadline}>Start in minutes</p>

                        <section className={styles.instructions}>
                            <CodeBlock dataLayer={{ name: 'Community Edition Docker Command' }}>
                                {`wget https://lenses.io/preview -O docker-compose.yml && ACCEPT_EULA=true docker compose up`}
                            </CodeBlock>

                            <ol className='list-decimal list-inside'>
                                <li>Run the docker command</li>
                                <li>
                                    Open the Lenses HQ at&nbsp;
                                    <span className={styles.highlight}>http://localhost:9991</span>
                                </li>
                                <li>
                                    Sign in with&nbsp;
                                    <span className={styles.highlight}>admin</span> /{' '}
                                    <span className={styles.highlight}>admin</span>
                                </li>
                            </ol>
                        </section>

                        <p className={styles.moreInfo}>
                            See detailed instructions for the deployment and RAM/VM requirements in{' '}
                            <a
                                href='https://docs.lenses.io/latest/getting-started/quickstart'
                                className={``}>
                                Lenses&nbsp;Docs
                            </a>
                        </p>
                    </article>
                </section>

                <section className={`${styles.section} ${styles.section2}`}>
                    <article className={styles.article}>
                        <h2 className={styles.title}>See Lenses in action</h2>
                        <figure className={styles.video}>
                            <iframe
                                title='Apache Kafka Docker + Lenses Community Edition | Explained'
                                src='https://player.vimeo.com/video/1039657610?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479'
                                allow='autoplay; fullscreen; picture-in-picture; clipboard-write'
                                className='w-full h-full'
                            />
                        </figure>
                    </article>
                </section>

                <section className={`${styles.section} ${styles.section3}`}>
                    <article className={styles.article}>
                        <h2 className={styles.title}>What’s included?</h2>
                        <Diagram className={styles.diagram} />
                    </article>
                </section>

                <div className='bg-grey-20'>
                    <PreFooter title={preFooterTitle} cardsData={cardsDataPrefooter} />
                </div>
            </div>
        </div>
    )
}

export default CommunityEditionPage
