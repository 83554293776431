import React from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import * as stylesModule from './styles.module.scss'

type Props = {
    children: string
    dataLayer?: object
}

export const CodeBlock = ({ children, dataLayer }: Props) => {
    const styles: any = stylesModule

    const [isCopied, setIsCopied] = React.useState(false)

    const handleCopy = () => {
        setIsCopied(true)
        setTimeout(() => {
            setIsCopied(false)
        }, 1500)
        if (typeof window !== 'undefined' && window.dataLayer) {
            window.dataLayer.push({
                event: 'code_snippet_copied',
                ...dataLayer,
            })
        }
    }

    return (
        <pre className={styles.container}>
            <code className={styles.code}>{children}</code>
            <CopyToClipboard onCopy={handleCopy} text={children}>
                <button className={styles.button} type={'button'}>
                    {isCopied ? 'copied!' : 'copy'}
                </button>
            </CopyToClipboard>
        </pre>
    )
}
